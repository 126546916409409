<template>
  <BaseLayout
    :centerHeading="true"
    :customClasses="['score-list']">
    <h2
      class="subtitle"
      v-show="!loading">
      {{ formattedLocation }} - {{ tableData.practice_area_name }}
    </h2>
    <h3
      class="date"
      v-show="!loading">
      {{ tableData.completed_at | moment('M/DD/YYYY') }}
    </h3>
    <action-links
      v-show="!loading">
      <Toggle
        :hasLabel="true"
        labelText="Mkt Data"
        @change="toggleMktData($event.target.checked)"
        :isChecked="mktDataActive" />
    </action-links>
    <Loading
      v-show="loading"
      text="collecting data" />
    <Table
      :loading="loading">
      <template slot="head">
        <t-row>
          <t-heading
            @click="sortBy('domain')"
            :class="sortedClass('domain')">
            Domain
          </t-heading>
          <t-heading>Location</t-heading>
          <t-heading>Target</t-heading>
          <t-heading>Tier</t-heading>
          <t-heading
            @click="sortBy('arc_score')"
            :class="sortedClass('arc_score')">
            ARC Score
          </t-heading>
          <t-heading
            :hasTip="true"
            thTip="(Double click to edit)"
            v-show="mktDataActive">
            Marketing Co.
          </t-heading>
          <t-heading
            :textCentered="true"
            v-if="hasOutreachKey">
            Add Prospect
          </t-heading>
          <t-heading
            @click="sortBy('arc_rank')"
            :class="sortedClass('arc_rank')">
            ARC Rank
          </t-heading>
          <t-heading>Score Date</t-heading>
          <t-heading>Include</t-heading>
          <t-heading>Focus</t-heading>
          <t-heading>View</t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(task, i) in sortedResults"
          :key="i">
          <t-cell>
            <a
              :href="`https://${task.domain}`"
              target="_blank"
              rel="noopener">
              {{ task.domain }}
            </a>
          </t-cell>
          <t-cell>{{ formattedLocation }}</t-cell>
          <t-cell
            :textCentered="true">
            <font-awesome-icon
              :icon="['fas', 'bullseye']"
              v-if="task.domain_target_id !== null">
            </font-awesome-icon>
          </t-cell>
          <t-cell>{{ tableData.market_tier }}</t-cell>
          <t-cell>{{ task.arc_score }}</t-cell>
          <t-cell
            :isEditable="true"
            @doneEditing="updateMarketingAgency(task, marketingCompany)"
            @dropdownInput="marketingCompany = $event"
            @dropdownSelect="marketingCompany = $event"
            :dropdownOptions="filteredCompanies"
            customOption="name"
            :defaultValue="
              task.marketing_company === ''
              ? ''
              : task.marketing_company
            "
            v-show="mktDataActive">
            {{ task.marketing_company === 'null' ? '' : task.marketing_company }}
          </t-cell>
          <t-cell
            v-if="hasOutreachKey">
            <Button
              :hasIcon="true"
              :hasIconOnly="true"
              icon="plus"
              :isRounded="true"
              :isSmall="true"
              btnType="purple"
              @click="toggleModal(i)"
              v-if="!isIncludedInOutreach(task.domain, i)" />
            <Button
              :hasIcon="true"
              :hasIconOnly="true"
              icon="pen"
              :isRounded="true"
              :isSmall="true"
              btnType="blue"
              @click="toggleModal(i, true, task.outreachProfile)"
              v-if="isIncludedInOutreach(task.domain) && task.outreachProfile" />
          </t-cell>
          <t-cell>{{ task.arc_rank }}</t-cell>
          <t-cell>
            {{ tableData.completed_at | moment('M/DD/YYYY') }}
          </t-cell>
          <t-cell
            :textCentered="true">
            <Input
              type="checkbox"
              :hasLabel="true"
              :inputId="`include-${task.id}`"
              :checked="isIncluded(task.results_excl_from_arc_score_calc, task.id)"
              @click.prevent="includeOrExclude(task.domain, task.results_excl_from_arc_score_calc, task.id, i)" />
          </t-cell>
          <t-cell :textCentered="true">{{ task.campaign_focus }}</t-cell>
          <t-cell :textCentered="true">
            <Button
              :hasLink="true"
              :btnLink="{ path: `${task.domain_slug}` }"
              :isView="true" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <OutreachModal
      v-if="hasOutreachKey"
      :selectedWebsite="selectedWebsite"
      :isActive="modalActive"
      :arcDetails="{
        location: formattedLocation,
        practiceArea: tableData.practice_area_name
      }"
      :mode="modalMode"
      @modal:closed="toggleModal"
      @prospect:listed="getWebsitesInOutreach(false, $event)"
      @prospect:created="getWebsitesInOutreach(true, $event)" />
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableHeading from '@/components/table/TableHeading'
import TableDataCell from '@/components/table/TableDataCell'
import Button from '@/components/form/Button'
import Input from '@/components/form/Input'
import BaseLayout from '@/views/BaseLayout'
import OutreachModal from '@/components/modal/OutreachModal'
import Loading from '@/components/misc/Loading'
import ActionLinks from '@/components/misc/ActionLinks'
import Toggle from '@/components/misc/Toggle'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ScoreList',
  components: {
    Table,
    Button,
    BaseLayout,
    Loading,
    Input,
    Toggle,
    ActionLinks,
    OutreachModal,
    't-row': TableRow,
    't-heading': TableHeading,
    't-cell': TableDataCell
  },
  data () {
    return {
      loading: false,
      sort: {
        key: 'arc_score',
        isAsc: true
      },
      marketingCompany: '',
      websitesInOutreach: [],
      modalActive: false,
      modalMode: 'create',
      selectedWebsite: '',
      outreachCompanies: [],
      mktDataActive: true
    }
  },
  created () {
    this.loading = true
    this.getAllData()
  },
  computed: {
    ...mapGetters({
      tableData: 'table/getTableData',
      hasOutreachKey: 'auth/hasOutreachKey'
    }),

    ...mapState({
      formData: state => state.form
    }),

    slug () {
      return `${this.$route.params.slug}?id=${this.$route.query.id}`
    },

    formattedLocation () {
      return this.formatLocation(this.tableData.dfs_location_name)
    },

    filteredCompanies () {
      return this.searchMarketingList(this.marketingCompany, this.formData.marketingCompanies).slice(0, 100)
    },

    sortedResults () {
      const list = this.tableData.results ? this.tableData.results : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }

      return list.filter(result => !this.isBlacklisted(result.domain))
    },

    allDomains () {
      const domains = []
      if (this.sortedResults.length > 0) {
        this.sortedResults.forEach(res => {
          domains.push(res.domain)
        })
      }
      return domains
    }
  },
  methods: {
    getAllData () {
      this.getTaskById(this.$route.query.id)
    },

    toggleMktData (checked) {
      this.mktDataActive = checked
    },

    getWebsitesInOutreach (created, outreachCompanies) {
      this.outreachCompanies = outreachCompanies.filter(company => {
        return company.status === 'prospect'
      })

      const webResults = []

      if (created) {
        webResults.push(outreachCompanies)
      } else {
        outreachCompanies.forEach(item => {
          webResults.push(item.websites)
        })
      }

      this.websitesInOutreach = webResults.flat().map(item => {
        const regexItem = item.replace(/https:\/\/www.|http:\/\/www.|https:\/\/|http:\/\/|/, '')
        const newStr = regexItem.replace('/', '')
        return newStr
      })
    },

    toggleModal (idx, edit, profile) {
      this.modalActive = !this.modalActive
      if (this.sortedResults[idx] !== undefined) {
        this.selectedWebsite = this.sortedResults[idx].domain
      }

      if (edit) {
        this.modalMode = 'edit'
      } else {
        this.modalMode = 'create'
      }
    },

    getArcRank () {
      var self = this
      if (this.sort.key === 'arc_score' && this.sort.isAsc) {
        this.sortedResults.forEach((result, i) => {
          result.arc_rank = i + 1
          result.domain_slug = self.getDomainSlug(
            self.tableData.completed_at,
            self.tableData.dfs_location_name,
            self.tableData.practice_area_name,
            result.domain,
            self.tableData.id
          )
        })
      }
    },

    isIncluded (excl, resultId) {
      return !excl.includes(resultId)
    },

    excludeFromArc (domain, resultId, excl, i) {
      this.$arc.post(`domains/${domain}/results_excl_from_arc_score_calc/${resultId}`)
        .then(res => {
          this.tableData.results[i].results_excl_from_arc_score_calc = res.data.results_excl_from_arc_score_calc
          this.$forceUpdate()
        })
        .catch(err => {
          console.error(err)
        })
    },

    includeInArc (domain, resultId, excl, i) {
      this.$arc.delete(`domains/${domain}/results_excl_from_arc_score_calc/${resultId}`)
        .then(res => {
          this.tableData.results[i].results_excl_from_arc_score_calc = res.data.results_excl_from_arc_score_calc
          this.$forceUpdate()
        })
        .catch(err => {
          console.error(err)
        })
    },

    includeOrExclude (domain, excl, resultId, i) {
      if (this.isIncluded(excl, resultId)) {
        this.excludeFromArc(domain, resultId, excl, i)
      } else {
        this.includeInArc(domain, resultId, excl, i)
      }
    },

    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },

    getTaskById (id) {
      this.loading = true
      this.$arc.get(`tasks/${id}`)
        .then(res => {
          this.$store.dispatch('table/setTableData', res.data)
          this.getArcRank()
          this.formDisabled = false
          this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.formError = true
          this.formErrors = err
          this.loading = false
        })
    },

    updateMarketingAgency (task, marketingAgency) {
      this.$arc.put('domains', {
        name: `${task.domain}`,
        ...(marketingAgency.length >= 3 && { marketing_company: `${marketingAgency}` })
      })
        .then(response => {
          this.$arc.get(`domains/${response.data.name}`)
            .then(res => {
              this.$set(task, 'marketing_company', res.data.marketing_company)
            })
            .catch(err => {
              console.error(err)
            })
          this.marketingCompany = ''
        })
        .catch(error => {
          console.error(error)
        })
    },

    isIncludedInOutreach (domain, i) {
      if (i) {
        if (this.websitesInOutreach.includes(domain)) {
          const profile = this.outreachCompanies.find(company => {
            return company.websites.some(website => website.includes(domain))
          })
          if (profile) {
            this.sortedResults[i].outreachProfile = profile
          }
        }
      }
      return this.websitesInOutreach.includes(domain)
    }
  }
}
</script>

<style lang="scss">
td {
  .svg-inline--fa {
    color: #000;
  }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
