/* eslint-disable no-unused-vars */
import axios from 'axios'
import _ from 'lodash'
import Cookies from 'js-cookie'
import { bus } from './event-bus'

require('dotenv').config()

const globalHeader = {
  'Content-Type': 'application/json'
}

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_ARC_SCORE_API,
  headers: {
    Authorization: `Basic ${btoa(process.env.VUE_APP_ARC_SCORE_API_KEY + ':')}`,
    'Content-Type': 'application/json'
  }
})

let managementApi

const getOutreachToken = async (graphId, apiKey) => {
  if (process.env.NODE_ENV === 'everservice') {
    return null
  }

  const resp = await axios({
    method: 'POST',
    url: 'https://sales-outreach.1p21.io/api/token_auth/',
    data: {
      username: `${graphId}`,
      password: `${apiKey}`
    },
    headers: globalHeader
  })
  return resp.status === 200
    ? Promise.resolve(resp.data.token)
    : Promise.reject(new Error('Could not get outreach key'))
}

async function createArcUser (user, graphId) {
  try {
    const resp = await axiosClient.post('users', {
      name: `${user}`,
      ms_graph_id: `${graphId}`
    })

    if (resp.status === 200 || resp.status === 201) {
      return resp.data
    } else {
      bus.$emit('user:create:failed', resp)
      throw new Error('Could not create arc user')
    }
  } catch (err) {
    console.error(err)
    bus.$emit('user:create:failed', err)
    throw err
  }
}

async function getArcUser (graphId) {
  const resp = await axiosClient.get('users/me', {
    params: {
      ms_graph_id: `${graphId}`
    }
  })
  return resp.status === 200
    ? Promise.resolve(resp.data)
    : Promise.reject(new Error('Could not get arc api key'))
}

async function getAccessToken () {
  try {
    const resp = await axios({
      method: 'POST',
      url: `https://${process.env.VUE_APP_AUTH0_DOMAIN}/oauth/token`,
      data: new URLSearchParams({
        client_id: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_SERVICE_CLIENT_ID}`,
        client_secret: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_SERVICE_CLIENT_SECRET}`,
        audience: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_SERVICE_AUDIENCE}`,
        grant_type: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_SERVICE_GRANT_TYPE}`
      }),
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
    if (resp.status === 200) {
      return resp.data.access_token
    } else {
      throw new Error('Could not get access token')
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

async function getManagementUser (id) {
  try {
    const resp = await managementApi.get(`users/${id}`)
    if (resp.status === 200) {
      return resp.data
    } else {
      throw new Error('Could not get management user')
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

export async function tokenSwap () {
  try {
    const accessToken = await getAccessToken()
    if (accessToken) {
      managementApi = axios.create({
        baseURL: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_SERVICE_AUDIENCE}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      })

      const items = { ...localStorage }
      const auth0Key = Object.keys(items).filter(s => s.includes('@@user@@'))[0]
      const authObj = items[auth0Key]

      if (!authObj) {
        return
      }
      const userId = JSON.parse(authObj).decodedToken.user.sub

      const managementUser = await getManagementUser(userId)

      if (managementUser) {
        let outreachApiKey
        const arcScoreUser = await getArcUser(managementUser.oid)

        if (!arcScoreUser.ms_graph_id) {
          try {
            const newArcUser = await createArcUser(managementUser.name, managementUser.oid)
            if (newArcUser) {
              Cookies.set('_.arc_score.arcScoreKey', newArcUser.api_key)
            }
            if (process.env.NODE_ENV !== 'everservice') {
              outreachApiKey = await getOutreachToken(newArcUser.ms_graph_id, process.env.VUE_APP_ARC_SCORE_API_KEY)
              if (outreachApiKey) {
                Cookies.set('_.arc_score.salesOutreachKey', outreachApiKey)
              }
            }
          } catch (err) {
            console.error(err)
          }
        } else {
          try {
            Cookies.set('_.arc_score.arcScoreKey', arcScoreUser.api_key)
            if (process.env.NODE_ENV !== 'everservice') {
              outreachApiKey = await getOutreachToken(arcScoreUser.ms_graph_id, process.env.VUE_APP_ARC_SCORE_API_KEY)
              if (outreachApiKey) {
                Cookies.set('_.arc_score.salesOutreachKey', outreachApiKey)
              }
            }
          } catch (err) {
            console.error(err)
          }
        }
      }
    }
    bus.$emit('token-swap')
  } catch (err) {
    console.error(err)
  }
}
