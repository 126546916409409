<template>
 <BaseLayout
   title="Domain Redirects"
   :customClasses="['domain-redirects']">
   <Form
      @submit.prevent="filterResults">
        <label>
         Per page
        </label>
        <Dropdown
          dropdownPlaceholder="Results per page"
          :hasIcon="true"
          :options="pagination.dropdownOptions"
          @select="pagination.per_page = $event"
          :inputVal="pagination.per_page"
          :isDisabled="loading" />
    </Form>
    <Loading v-show="loading" text="Loading domain redirects" />
    <Table :loading="loading">
      <template slot="head">
        <t-row>
          <t-heading
            @click="sortBy('original_domain.name')"
            :class="sortedClass('original_domain.name')">
            From
          </t-heading>
          <t-heading
            @click="sortBy('original_domain.marketing_company')"
            :class="sortedClass('original_domain.marketing_company')">
            Agency
          </t-heading>
          <t-heading
            @click="sortBy('status_code')"
            :class="sortedClass('status_code')">
            Type
          </t-heading>
          <t-heading
            @click="sortBy('target_domain.name')"
            :class="sortedClass('target_domain.name')">
            To
          </t-heading>
          <t-heading
            @click="sortBy('target_domain.marketing_company')"
            :class="sortedClass('target_domain.marketing_company')">
            Agency
          </t-heading>
          <t-heading
            @click="sortBy('redirected_at')"
            :class="sortedClass('redirected_at')">
            Date Discovered
          </t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(result, i) in sortedResults"
          :key="i">
          <t-cell>
            <a
              :href="result.original_domain.name"
              target="_blank"
              rel="noopener">
              {{ result.original_domain.name }}
            </a>
          </t-cell>
          <t-cell
            :noDecoration=true>
            <div class="logo-container" v-if="result.original_domain.marketing_company !== null">
              <img height="24" width="24" :src="result.original_domain.marketing_company.favicon_url || result.original_domain.marketing_company.icon_url || require('../../assets/img/fallback_fav.png')"/>
              <a
                :href="result.original_domain.marketing_company.url"
                target="_blank"
                rel="noopener noreferrer">
                {{ result.original_domain.marketing_company.name }}
              </a>
            </div>
          </t-cell>
          <t-cell>{{ result.status_code }}</t-cell>
          <t-cell>
            <a
              :href="result.target_domain.name"
              target="_blank"
              rel="noopener">
              {{ result.target_domain.name }}
            </a>
          </t-cell>
          <t-cell
            :noDecoration=true>
            <div class="logo-container" v-if="result.target_domain.marketing_company !== null">
              <img height="24" width="24" :src="result.target_domain.marketing_company.favicon_url || result.target_domain.marketing_company.icon_url || require('../../assets/img/fallback_fav.png')"/>
              <a
                :href="result.target_domain.marketing_company.url"
                target="_blank"
                rel="noopener noreferrer">
                {{ result.target_domain.marketing_company.name }}
              </a>
            </div>
            <!-- <div class="logo-container" v-else>
              Unknown
            </div> -->
          </t-cell>
          <t-cell>{{ result.redirected_at | moment('M/DD/YYYY') }}</t-cell>
          <t-cell>
            <Button
              :hasLink="true"
              :btnLink="{
                name: 'profile',
                query: {
                  domain: formatDomain(result.target_domain.name)
                }
              }"
              :isView="true" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <Pagination
      v-if="!loading"
      :totalItems="redirects.total"
      :perPage="pagination.per_page"
      :currentPage="pagination.page"
      :nextNum="redirects.next_num"
      :prevNum="redirects.prev_num"
      @pageChanged="changePage($event)" />
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableDataCell from '@/components/table/TableDataCell'
import TableHeading from '@/components/table/TableHeading'
import Button from '@/components/form/Button'
import Dropdown from '@/components/form/Dropdown'
import Form from '@/components/form/Form'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import Pagination from '@/components/misc/Pagination'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'DomainRedirects',
  components: {
    Table,
    Button,
    Loading,
    Pagination,
    BaseLayout,
    Form,
    Dropdown,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading
  },
  data () {
    return {
      loading: false,
      redirects: [],
      sort: {
        key: '',
        isAsc: false
      },
      pagination: {
        page: 1,
        per_page: 100,
        dropdownOptions: [20, 50, 100, 250, 500, 1000]
      }
    }
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    sortedResults () {
      const list = this.redirects.redirects ? this.redirects.redirects : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = _.get(a, this.sort.key)
          b = _.get(b, this.sort.key)
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      return list.slice((this.pagination.page - 1) * this.pagination.per_page, this.pagination.page * this.pagination.per_page)
    }
  },
  mounted () {
    if (this.$route.query.page) {
      this.pagination.page = Number(this.$route.query.page)
    }
    this.getRedirects()
  },
  methods: {
    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },

    getRedirects () {
      this.loading = true
      this.$arc.get('domains/redirects?per_page=500', {
        params: {
          ...(this.pagination.page ? { page: this.pagination.page } : {})
        }
      })
        .then(res => {
          this.redirects = res.data
          this.loading = false
          this.getPagedResults()
        })
        .catch(err => {
          console.error(err)
          this.loading = false
        })
    },

    getPagedResults () {
      const promises = []
      for (
        let i = 2;
        i < Math.ceil(this.redirects.total / this.redirects.per_page) + 1;
        i++
      ) {
        promises.push(
          this.$arc.get(`domains/redirects?page=${i}&per_page=500`)
        )
      }
      Promise.all(promises).then((values) => {
        values.map(p => {
          this.redirects.redirects.push(...p.data.redirects)
        })
      })
      this.loading = false
    },

    updatedPerPage (e) {
      this.pagination.per_page = e
      this.getRedirects()
    },

    changePage (e) {
      this.pagination.page = e
    },

    formatDomain (domain) {
      return domain.toString().includes('https://www.') || domain.toString().includes('http://www.')
        ? domain.toString().replace('https://www.', '').replace('http://www.', '')
        : domain.toString().replace('https://', '').replace('http://', '')
    },

    formatSlug (completedAt, location, practiceArea, taskId) {
      const completed = this.$moment(completedAt).format('M-DD-YYYY')
      const newLocation = location.toLowerCase().replace(', ', '-').replace(' ', '-')
      const area = practiceArea.toLowerCase().replace(' ', '-')
      return `${newLocation}/${area}/${completed}?id=${taskId}`
    }
  }
}
</script>

<style lang="scss">
.domain-redirects {
  form {
    align-items: center;
    margin-bottom: 30px;
    justify-content: flex-end;
    strong {
      margin-bottom: 1rem;
    }
    label {
      margin-right: 1rem;
    }
    > div {
      flex-basis: calc((100% / 4) - 150px) !important;
      &.flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
          font-weight: 600;
          margin-bottom: 10px;
          display: block;
          width: 100%;
        }
        .input-container {
          flex-basis: calc((100% / 2) - 127px);
        }
      }
    }
    strong {
      margin-right: 15px;
    }
  }
  .logo-container {
    img {
      margin: 0 1rem;
      vertical-align: bottom;
      display: inline-block;
    }
  }
}
</style>
