<template>
  <BaseLayout
    title="Power Rankings Archives"
    :customClasses="['archive']">
    <Form
      @submit.prevent="getRecentTasks">
      <strong>Filters</strong>
      <Input
        placeholder="Url"
        :hasLabel="true"
        labelText="Url"
        v-model="form.url"
        :disabled="loading" />
      <Dropdown
        :options="filteredLocations"
        :isSearch="true"
        dropdownPlaceholder="Start Typing Location"
        :inputHasLabel="true"
        inputId="city"
        inputLabelText="Location"
        ref="filterDropdown1"
        @select="form.location = $event"
        @input="debounceLocationName($event)"
        :inputVal="form.location"
        :isDisabled="loading" />
      <Dropdown
        :hasIcon="true"
        :options="formData.practiceAreas"
        dropdownPlaceholder="Practice Area"
        :inputHasLabel="true"
        inputId="practiceArea"
        inputLabelText="Practice Area"
        ref="filterDropdown2"
        @select="form.practiceArea = $event"
        :isDisabled="loading" />
      <Dropdown
        dropdownPlaceholder="Results per page"
        :inputHasLabel="true"
        inputLabelText="Results Per Page"
        :hasIcon="true"
        :options="pagination.dropdownOptions"
        @select="pagination.per_page = $event"
        :inputVal="pagination.per_page"
        :isDisabled="loading" />
      <Button
        type="submit"
        text="Apply"
        :isDisabled="loading" />
    </Form>
    <action-links
        textAlign="right"
        v-if="!loading">
      <Button
        @click="clearFilters"
        text="Clear filters" />
    </action-links>
    <Loading
      v-show="loading"
      text="Loading archives" />
    <Table
      :loading="loading">
      <template slot="head">
        <t-row>
          <t-heading>Report Type</t-heading>
          <t-heading
            @click="sortBy('dfs_location_name')"
            :class="sortedClass('dfs_location_name')">
            Location
          </t-heading>
          <t-heading>Practice Area</t-heading>
          <t-heading
            @click="sortBy('completed_at')"
            :class="sortedClass('completed_at')">
            Date Ran
          </t-heading>
          <t-heading>
            Type
          </t-heading>
          <t-heading>
            Ran By
          </t-heading>
          <t-heading
            :textCentered="true">
            View Report
          </t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(result, i) in sortedResults"
          :key="i">
          <t-cell>
            Power Rankings
          </t-cell>
          <t-cell>{{ formatLocation(result.dfs_location_name) }}</t-cell>
          <t-cell style="text-transform: capitalize;">{{ result.practice_area_name }}</t-cell>
          <t-cell>{{ result.completed_at | moment('M/DD/YYYY [at] hh:mma') }}</t-cell>
          <t-cell>
            {{ result.url ? result.url : 'City' }}
          </t-cell>
          <t-cell>
            {{ result.sales_outreach_user
              ? `${result.sales_outreach_user.first_name} ${result.sales_outreach_user.last_name}`
              : result.user
                ? `${result.user.name}`
                : 'N/A' }}
          </t-cell>
          <t-cell
            :textCentered="true">
            <Button
              :hasLink="true"
              :btnLink="{
                path: getReportSlug(result)
              }"
              :isView="true" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <Pagination
      v-if="!loading"
      :totalItems="archiveData.total"
      :perPage="archiveData.per_page"
      :currentPage="archiveData.page"
      :nextNum="archiveData.next_num"
      :prevNum="archiveData.prev_num"
      @pageChanged="changePage($event)" />
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableDataCell from '@/components/table/TableDataCell'
import TableHeading from '@/components/table/TableHeading'
import Dropdown from '@/components/form/Dropdown'
import BaseLayout from '@/views/BaseLayout'
import Button from '@/components/form/Button'
import Input from '@/components/form/Input'
import Loading from '@/components/misc/Loading'
import Pagination from '@/components/misc/Pagination'
import Form from '@/components/form/Form'
import { mapState } from 'vuex'
import ActionLinks from '@/components/misc/ActionLinks'
import _ from 'lodash'

export default {
  name: 'PowerRankingsArchives',
  components: {
    Table,
    Dropdown,
    Input,
    Loading,
    Form,
    Button,
    Pagination,
    ActionLinks,
    BaseLayout,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading
  },
  data () {
    return {
      pagination: {
        per_page: 100,
        currentPage: 1,
        dropdownOptions: [20, 50, 100, 250, 500, 1000]
      },
      loading: false,
      sort: {
        key: 'completed_at',
        isAsc: false
      },
      archiveData: {},
      form: {
        location: '',
        url: '',
        practiceArea: ''
      },
      filteredLocations: []
    }
  },
  created () {
    this.getRecentTasks()
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    // filteredLocations () {
    //   return this.searchLocationList(this.form.location, this.formData.usLocations).slice(0, 100)
    // },

    formattedLocation () {
      return this.form.location.replaceAll(', ', ',')
    },

    sortedResults () {
      const list = this.archiveData.tasks ? this.archiveData.tasks : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      return list
    }
  },
  methods: {
    debounceLocationName: _.debounce(function (val) {
      if (val.length > 2) {
        const cleanedVal = val.replace(/, /g, ',')
        this.getLocations(cleanedVal)
      } else {
        this.filteredLocations = []
      }
    }, 200),

    getReportSlug (result) {
      let slug = ''
      if (result.url !== null) {
        slug = this.getDomainSlug(
          result.completed_at,
          result.dfs_location_name,
          result.practice_area_name,
          this.cleanWebUrl(result.url),
          result.id,
          'power-rankings-detail'
        )
      } else {
        slug = this.getTaskSlug(
          result.completed_at,
          result.dfs_location_name,
          result.practice_area_name,
          result.id,
          'power-rankings-overview'
        )
      }
      return slug
    },

    getLocations (name) {
      this.filteredLocations = this.searchUsLocationList(name).map(location => {
        return location.replace(/,/g, ', ')
      })
    },

    log (e) {
      console.log(e)
    },

    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },

    updatePerPage (e) {
      this.pagination.per_page = e
      this.getRecentTasks()
    },

    changePage (e) {
      this.pagination.currentPage = e
      this.getRecentTasks()
    },

    getRecentTasks () {
      this.loading = true
      this.$arc.get('tasks', {
        params: {
          ...(this.pagination.currentPage ? { page: this.pagination.currentPage } : {}),
          ...(this.pagination.per_page ? { per_page: this.pagination.per_page } : {}),
          ...(this.form.location ? { location_name_like: this.formattedLocation } : {}),
          ...(this.form.practiceArea ? { practice_area_name_like: this.form.practiceArea } : {}),
          ...(this.form.url ? { url_like: this.form.url } : {}),
          power_ranking: 1
        }
      })
        .then(res => {
          this.archiveData = res.data
          this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.loading = false
        })
    },

    clearFilters () {
      var self = this
      Object.keys(this.form).forEach(function (key) {
        self.form[key] = ''
        return self.form
      })
      this.$refs.filterDropdown1.selected = ''
      this.$refs.filterDropdown2.selected = ''
      this.getRecentTasks()
    }
  }
}
</script>

<style lang="scss">
.archive {
  form {
    align-items: flex-end;
    margin-bottom: 30px;
    justify-content: space-between;
    strong {
      margin-bottom: 1rem;
    }
    > div {
      flex-basis: calc((100% / 4) - 30px) !important;
      &.flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
          font-weight: 600;
          margin-bottom: 10px;
          display: block;
          width: 100%;
        }
        .input-container {
          flex-basis: calc((100% / 2) - 7px);
        }
      }
    }
    strong {
      margin-right: 15px;
    }
  }
}
</style>
